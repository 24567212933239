<template>
  <div class="labs-page">
    <v-row v-if="isLoading" class="justify-center mt-2">
      <v-progress-circular color="primary" indeterminate />
    </v-row>
    <div v-else>
      <div v-if="!url">
        <v-card color="#fff" flat>
          <v-card-title class="pt-6 pb-9 px-4">
            <h3 v-if="warningHeader">{{ warningHeader }}</h3>
            <h3 v-else>
              <span>{{ warningHeader1 }}</span>
            </h3>
          </v-card-title>
        </v-card>
        <div v-if="!disabled" class="pt-6 px-4">
          <Btn
            :disabled="viewPartner"
            theme="primary"
            class="mb-4"
            @click="goToLink('male')"
          >
            Start Health History Form
          </Btn>
        </div>
      </div>
      <div v-else style="background: #fff">
        <iframe :src="url" style="width: 100%; height: 91vh"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { auth, api, partner } from '../../sharedPlugin';

export default {
  data: () => ({
    url: null,
    warningHeader: '',
    warningHeader1: `We would like to learn more about you to create your personalized treatment plan. This includes your medical history, any previous fertility tests or treatments you've had, and your family history. Your progress will be saved at each step, and if you need a break, you can click "Start Health History Form" when you're ready to continue!`,
    disabled: false,
    isLoading: false
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    viewPartner() {
      return this.getPartner?.viewPartner;
    }
  },
  methods: {
    goToLink(type) {
      console.log(this.user);
      let url = `https://glittering-figolla-008a8c.netlify.app/?patientid=${this.user.patientId}#token=${this.user.token}`;

      if (type === 'male') {
        url = `https://glittering-figolla-008a8c.netlify.app/?patientid=${this.user.patientId}#token=${this.user.token}`;
      }

      this.url = url;
    },
    getIntakeFormStatus() {
      let url = window.location.href;
      let result = url.replace(/(^\w+:|^)\/\//, '');
      let index = result.indexOf(':');
      url = result.substring(0, index);

      this.isLoading = true;
      api.IntakeEndpoint.getStatus()
        .then((result) => {
          if (result && result.length > 0) {
            this.warningHeader =
              'Thank you for completing your health history form. Your information has been successfully submitted.';
            this.disabled = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    }
  },
  created() {
    this.getIntakeFormStatus();
  }
};
</script>
<style lang="scss">
.labs-page {
  .v-card__title {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
#form {
  background: #ffffff;
}
</style>
